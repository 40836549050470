<template>
  <div>
    <treatment-history-detail :backRoute="backRoute" patientDetailRoute="SuperAdminPatientDetail" />
  </div>
</template>
<script>
import PatientTreatmentHistoryDetails from "../../general-component/treatment-history/TreatmentDetails";
export default {
  name: "AdminPatientDetails",
  components: {
    "treatment-history-detail": PatientTreatmentHistoryDetails
  },
  data() {
    return {
      backRoute: "SuperAdminPatientDetail"
    };
  },
  created() {
    if (this.$route.query.backRoute) {
      this.backRoute = this.$route.query.backRoute;
    }
  }
};
</script>
